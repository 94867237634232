const routes = [
  {
    path: ["/credito/propostas"],
    exact: true,
    component: "Credito/PageConsultaPropostasCredito",
  },
  {
    path: ["/credito/proposta/analise/:idProposta"],
    exact: true,
    component: "Credito/AnaliseProposta",
  },
  {
    path: ["/comercial/simulacoes"],
    exact: true,
    component: "Comercial/ConsultaSimulacoesProprietario",
  },
  {
    path: ["/comercial/simulacoes/detalhe"],
    exact: true,
    component: "Comercial/DetalharSimulacoes",
  },
  {
    path: ["/comercial/nova-simulacao"],
    exact: true,
    component: "Comercial/NovaSimulacao",
  },
  {
    path: ["/comercial/propostas"],
    exact: true,
    component: "Comercial/PageConsultaPropostasComercial",
  },
  {
    path: ["/comercial/proposta/detalhe/:idProposta"],
    exact: true,
    component: "Comercial/DetalharProposta",
  },
  {
    path: ["/comercial/adesoes"],
    exact: true,
    component: "Comercial/AdesaoImobiliaria/PageListagemAdesaoImobiliarias",
  },
  {
    path: ["/comercial/adesoes/:idAdesao"],
    exact: true,
    component: "Comercial/AdesaoImobiliaria/DetalheAdesaoImobiliaria",
  },
  {
    path: ["/financeiro/contas-receber/baixa-parcelas"],
    exact: true,
    component: "Financeiro/ContasReceber/BaixaParcelas",
  },
  {
    path: ["/financeiro/garantia-contrato/baixa-parcelas-caucao"],
    exact: true,
    component: "Financeiro/GarantiaContrato/BaixaParcelasCaucao",
  },
  {
    path: ["/financeiro/garantia-contrato/baixa-devolucao-caucao"],
    exact: true,
    component: "Financeiro/GarantiaContrato/BaixaDevolucaoCaucao",
  },
  {
    path: ["/financeiro/contas-pagar/baixa-parcelas"],
    exact: true,
    component: "Financeiro/ContasPagar/BaixaParcelas",
  },
  {
    path: ["/financeiro/baixas/desfazer"],
    exact: true,
    component: "Financeiro/DesfazerBaixas",
  },
  {
    path: ["/financeiro/relatorio-conciliacao"],
    exact: true,
    component: "Financeiro/RelatorioConciliacao",
  },
  {
    path: ["/financeiro/conciliacao-pessoas"],
    exact: true,
    component: "Financeiro/Conciliacao/ConciliacaoPessoas",
  },
  {
    path: ["/financeiro/conciliacao-pessoas/:idPessoa"],
    exact: true,
    component: "Financeiro/Conciliacao/ConciliacaoDetalhePessoa",
  },
  {
    path: ["/financeiro/conciliacao-transacoes/:idTransacao"],
    exact: true,
    component: "Financeiro/Conciliacao/ConciliacaoTransacao",
  },
  {
    path: ["/contrato/contratos"],
    exact: true,
    component: "Contrato",
  },
  {
    path: "/contrato/pendentes-relocacao",
    exact: true,
    component: "Contrato/Pendentes",
  },
  {
    path: ["/contrato/detalhe/:idContrato"],
    exact: true,
    component: "Contrato/DetalhesContrato",
  },
  {
    path: ["/financeiro/consulta-parcelas"],
    exact: true,
    component: "Financeiro/ConsultaParcelas",
  },
  {
    path: ["/financeiro/cobranca"],
    exact: true,
    component: "Financeiro/Cobranca/ListagemCobranca",
  },
  {
    path: ["/financeiro/cobranca/detalhe/:idContrato"],
    exact: true,
    component: "Financeiro/Cobranca/DetalheCobranca",
  },
  {
    path: ["/financeiro/cobranca/gestao-responsavel-cobranca"],
    exact: true,
    component: "Financeiro/Cobranca/GestaoResponsavelCobranca",
  },
  {
    path: ["/financeiro/gestao-comissoes"],
    exact: true,
    component: "Financeiro/GestaoComissoes",
  },
  {
    path: ["/financeiro/gestao-remessa-boletos"],
    exact: true,
    component: "Financeiro/GestaoBoletos/Remessas",
  },
  {
    path: ["/financeiro/gestao-remessa-boletos/:idRemessa"],
    exact: true,
    component: "Financeiro/GestaoBoletos/Boletos",
  },
  {
    path: ["/financeiro/remessa-boletos"],
    exact: true,
    component: "Financeiro/GestaoBoletos/GeracaoRemessaBoletos",
  },
  {
    path: ["/comissao/detalhe/:idNotaFiscal"],
    exact: true,
    component: "Financeiro/GestaoComissoes/DetalheNotaFiscal",
  },
  {
    path: ["/imobiliarias"],
    exact: true,
    component: "Imobiliaria/ListagemImobiliarias",
  },
  {
    path: ["/parceiros"],
    exact: true,
    component: "Parceiro/ListaParceiros",
  },
  {
    path: ["/blocklist-antecipacao"],
    exact: true,
    component: "BlockListAntecipacao/ListaBlockList",
  },
  {
    path: ["/parceiro/detalhe/:idParceiro"],
    exact: true,
    component: "Parceiro/FormParceiro",
  },
  {
    path: '/parceiro/detalhe',
    exact: true,
    component: "Parceiro/FormParceiro",
  },
  {
    path: ["/credito/adesoes"],
    exact: true,
    component: "Credito/AdesaoImobiliaria/PageListagemAdesaoImobiliarias",
  },
  {
    path: ["/credito/adesoes/:idAdesao"],
    exact: true,
    component: "Credito/AdesaoImobiliaria/DetalheAdesaoImobiliaria",
  },
  {
    path: ["/adesao/nova-adesao"],
    exact: true,
    component: "Comercial/AdesaoImobiliaria/NovaAdesao",
  },
  {
    path: ["/imobiliarias/detalhe/:idAdesao"],
    exact: true,
    component: "Imobiliaria/DetalheImobiliaria",
  },
  {
    path: ["/imobiliarias/nova-imobiliaria"],
    exact: true,
    component: "Imobiliaria/NovaImobiliaria",
  },
  {
    path: ["/campanhas"],
    exact: true,
    component: "Campanha/ListagemCampanhas",
  },
  {
    path: ["/campanhas/detalhe"],
    exact: true,
    component: "Campanha/DetalheCampanha",
  },
  {
    path: ["/campanhas/nova-campanha"],
    exact: true,
    component: "Campanha/NovaCampanha",
  },
  {
    path: ["/campanhas-autorizacao"],
    exact: true,
    component: "Campanha/ListagemCampanhas/ListagemCampanhasAutorizacao",
  },
  {
    path: ["/campanhas/autorizacao"],
    exact: true,
    component: "Campanha/DetalheAutorizacao",
  },
  {
    path: ["/blocklist"],
    exact: true,
    component: "Blocklist",
  },
  {
    path: ["/unsubscribe-email"],
    exact: true,
    component: "Unsubscribe",
  },
  {
    path: ["/usuario/alterar-senha"],
    exact: true,
    component: "Usuario/AlterarSenha"
  },
  {
    path: ["/teste"],
    exact: true,
    component: "Teste",
  },
  {
    path: ["/cadastros/proprietarios"],
    exact: true,
    component: "Proprietario",
  },
  {
    path: ["/cadastros/proprietarios/:documento"],
    exact: true,
    component: "Proprietario/Detalhes",
  },
  {
    path: ["/erp-adesao-imobiliarias"],
    exact: true,
    component: "Erp/AdesaoImobiliarias",
  },
  {
    path: ["/erp-locadores"],
    exact: true,
    component: "Erp/Locadores",
  },
  {
    path: ["/erp-contratos-locacao"],
    exact: true,
    component: "Erp/ContratosLocacao",
  },
];

export default routes;
